
import { defineComponent } from 'vue'
import user from '@/utils/user'
import { welcomeTime, welcome } from '@/utils/util'
import { ElNotification } from 'element-plus'
import { getAppName } from '@/api/base'

export default defineComponent({
    name: 'Login',
    data () {
        return {
            projectName: '欢迎使用',
            currentMode: 'mobile',
            phone: '',
            code: '',
            username: '',
            password: '',
            time: 0,
            canSwitch: false,
            recordNumber: ''
        }
    },
    mounted () {
        const project_logo_square = localStorage.getItem('project_logo_square_c')
        const project_name = localStorage.getItem('project_name_c')
        if (project_logo_square && project_name) {
            user.changeFavicon(project_logo_square)
            document.title = project_name + '-登录'
            // this.projectName = project_name
        }
        if (getAppName() === '蓝工盾') {
            this.canSwitch = true
            this.recordNumber = '京ICP备2023018104号-1'
        } else if (getAppName() === '安心优选') {
            this.recordNumber = '苏ICP备2022007642号-1'
        } else if (getAppName() === '企护星') {
            this.recordNumber = '鄂ICP备2023012241号-1'
        } else if (getAppName() === '预安企护') {
            this.recordNumber = '浙ICP备2024055271号-1'
        } else {
            this.recordNumber = '测试ICP备202404101号-1'
        }
    },
    methods: {
        switchMode () {
            this.currentMode = this.currentMode === 'mobile' ? 'account' : 'mobile'
        },
        async submit () {
            if (this.currentMode === 'mobile') {
                // 验证输入信息
                if (!/^1\d{10}$/.test(this.phone)) {
                    this.$message.warning('请输入手机号码')
                    return
                }
                if (!this.code) {
                    this.$message.warning('请输入验证码')
                    return
                }
                await user.login({
                    mobile: this.phone,
                    smsCode: this.code,
                    loginMode: this.currentMode
                })
            } else {
                if (!this.username) {
                    this.$message.warning('请输入用户名')
                    return
                }
                if (!this.password) {
                    this.$message.warning('请输入密码')
                    return
                }
                await user.login({
                    username: this.username,
                    password: this.password,
                    loginMode: this.currentMode
                })
            }

            this.$router.push({
                path: '/'
            })
            this.$message.success(welcomeTime() + '，' + user.userName)
            ElNotification({
                title: welcomeTime() + '，' + user.userName,
                message: welcome(),
                type: 'success'
            })
        },
        // 获取短信验证码
        async getSms () {
            // 对手机号码验证
            if (/^1\d{10}$/.test(this.phone)) {
                await user.getSms(this.phone)
                this.$message.success('短信发送成功，请注意查收！')
                // 设置倒计时
                this.setTime()
            } else {
                this.$message.warning('请输入手机号码')
            }
        },
        setTime () {
            this.time = 60
            const timer = setInterval(() => {
                this.time -= 1
                if (this.time === 0) {
                    clearInterval(timer)
                }
            }, 1000)
        }
    }
})
